:root {
  --primary-color: #729e7a;
  --primary-light-color: #a9d7ae;
  --secondary-color: #404041;
  --secondary-light-color: #525252;
}

@import "~bootstrap/dist/css/bootstrap-grid.css";
@import "~bootstrap/dist/css/bootstrap-utilities.css";
@import "~ngx-toastr/toastr.css";

/*********************************
        FONTS
*********************************/
@font-face {
  font-family: 'DIN';
  src: url('/assets/alto/fonts/DIN-Light.woff2') format('woff2'),
    url('/assets/alto/fonts/DIN-Light.woff2') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN';
  src: url('/assets/alto/fonts/DIN-Bold.woff2') format('woff2'),
    url('/assets/alto/fonts/DIN-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN';
  src: url('/assets/alto/fonts/DIN-Regular.woff2') format('woff2'),
    url('/assets/alto/fonts/DIN-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN';
  src: url('/assets/alto/fonts/DIN-Medium.woff2') format('woff2'),
    url('/assets/alto/fonts/DIN-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* barlow-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/alto/fonts/barlow-v12-latin-300.eot');
  /* IE9 Compat Modes */
  src: url('/assets/alto/fonts/barlow-v12-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/alto/fonts/barlow-v12-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/alto/fonts/barlow-v12-latin-300.svg#Barlow') format('svg');
  /* Legacy iOS */
}

/* barlow-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/alto/fonts/barlow-v12-latin-regular.eot');
  /* IE9 Compat Modes */
  src: url('/assets/alto/fonts/barlow-v12-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/alto/fonts/barlow-v12-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/alto/fonts/barlow-v12-latin-regular.svg#Barlow') format('svg');
  /* Legacy iOS */
}

/* barlow-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/alto/fonts/barlow-v12-latin-500.eot');
  /* IE9 Compat Modes */
  src: url('/assets/alto/fonts/barlow-v12-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/alto/fonts/barlow-v12-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/alto/fonts/barlow-v12-latin-500.svg#Barlow') format('svg');
  /* Legacy iOS */
}

/* barlow-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/alto/fonts/barlow-v12-latin-600.eot');
  /* IE9 Compat Modes */
  src: url('/assets/alto/fonts/barlow-v12-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/alto/fonts/barlow-v12-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/alto/fonts/barlow-v12-latin-600.svg#Barlow') format('svg');
  /* Legacy iOS */
}

/* barlow-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/alto/fonts/barlow-v12-latin-700.eot');
  /* IE9 Compat Modes */
  src: url('/assets/alto/fonts/barlow-v12-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/alto/fonts/barlow-v12-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/alto/fonts/barlow-v12-latin-700.svg#Barlow') format('svg');
  /* Legacy iOS */
}

/* barlow-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/alto/fonts/barlow-v12-latin-800.eot');
  /* IE9 Compat Modes */
  src: url('/assets/alto/fonts/barlow-v12-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/alto/fonts/barlow-v12-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/alto/fonts/barlow-v12-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/alto/fonts/barlow-v12-latin-800.svg#Barlow') format('svg');
  /* Legacy iOS */
}

/*********************************
      ROOT
*********************************/
:root {
  --font-primary: 'Barlow';
  --font-secondary: 'DIN';
  --primary: #E7E5E2;
  --secondary: #867D6F;
}

/*********************************
      PREDEFINED
*********************************/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  font-family: var(--font-secondary);
  color: #362D2C;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100vh;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

.container-fluid {
  padding: 0 3.35%;
}

.container {
  padding: 0 6%;
  height: 100%;
  max-width: inherit;
}

.container-left {
  padding-left: 6%;
  height: 100%;
}

.container-right {
  padding-right: 6%;
  height: 100%;
}

.alert {
  padding: 50px;
  border-radius: 30px;

  &.alert-warning {
    background-color: #fff89a;
  }
}

/*********************************
      HEADER
*********************************/
header {
  background-color: var(--primary);
  border-radius: 0 0 140px 140px;
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.top-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 65px 0;
}

.logo-box a {
  display: block;
  height: 211px;
  width: auto;
}

.logo-box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.icons-box {
  display: flex;
  gap: 50px;

}

.circle-box a {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-secondary);
  width: 142px;
  height: 142px;
  font-size: 100px;
  background-color: var(--secondary);
  color: white;
  border-radius: 50%;
  font-size: 100px;
  text-align: center;
  line-height: 1;
}

.circle-box img {
  object-fit: contain;
  width: 90px;
  height: 90px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(234deg) brightness(103%) contrast(103%);
}

.lang-btn {
  background-color: var(--secondary);
  border-radius: 50%;
  width: 142px;
  height: 142px;
  border: none;
  position: relative;
  font-size: 50px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lang-btn.active {
  background-color: #beb9b0;
}

.lang-btn img {
  object-fit: contain;
  width: 80px;
  height: 80px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(234deg) brightness(103%) contrast(103%);
}

.lang-btn img.play {
  height: 60px;
  width: 60px;
}

.lang-menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style-type: none;
  position: absolute;
  right: 130px;
  top: 260px;
  width: auto;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}

.hidden {
  top: -260%;
}

.top-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary);
  border-radius: 72px;
  padding: 42px 0;
  min-width: 690px;
}

.top-btn-img {
  height: 60px;
  width: auto;
  margin-right: 50px;
}

.top-btn-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.top-btn-text {
  color: white;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.25;
}

/*********************************
      MAIN PAGE
*********************************/

.main-page {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  overflow: hidden;
}

.main-page::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: rgba(255, 255, 255, .95);
}

.main-page-block {
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.text-box {
  padding-top: 552px;
  padding-bottom: 200px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all .4s;
}

.text-box h2 {
  font-size: 200px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.text-box p {
  font-family: var(--font-secondary);
  font-size: 60px;
  line-height: 1.16;
  margin: 0;
  margin-top: 100px;
}

.right-text-box {
  padding-left: 178px;
  padding-top: 552px;
  padding-bottom: 200px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-text-box h2 {
  font-size: 200px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.right-text-box p {
  font-family: var(--font-secondary);
  font-size: 60px;
  line-height: 1.16;
  margin: 0;
  margin-top: 100px;
}

.small-text p {
  width: 1242px;
}

.break-text p {
  max-width: 1433px;
}

.small-break-text p {
  max-width: 1160px;
}

.main-btn {
  display: block;
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: white;
  font-family: var(--font-secondary);
  font-size: 50px;
  line-height: 2;
  text-decoration: none;
  border-radius: 125px;
  padding: 32px 64px;
  text-align: center;
  min-width: 880px;
}

.main-btn:hover {
  opacity: .8;
}
.main-btn-outline {
  background-color:  #FFFFFF !important;
  border: 1px solid var(--secondary) !important;
  color: var(--secondary) !important;
}
.light-btn {
  background-color: #E5E2E2;
  color: #362D2C;
  font-weight: 700;
  font-size: 60px;
  border: 1px solid #867D6F;
}

.main-page-img-box {
  width: 50%;
  height: 100%;
  overflow-y: hidden;
}

.main-page-img-box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.etape-box {
  display: flex;
  color: #362D2C;
  font-size: 50px;
  line-height: 0.5;
  margin-bottom: 160px;
  position: relative;
}

.lines-box {
  display: flex;
  gap: 42px;
  position: absolute;
  top: 65px;
  left: 0;
  width: auto;
  height: 5px;
}

.dark-line {
  background-color: #867D6F;
  height: 5px;
  width: 444px;
  border-radius: 4.5px;
}

.light-line {
  background-color: #D9D9D9;
  height: 5px;
  width: 444px;
  border-radius: 4.5px;
}

.main-page-buttons {
  display: flex;
  gap: 55px;
}

.stars-box {
  background-color: #E7E5E2;
  border-radius: 53px;
  padding: 180px 80px 155px 80px;
  width: 1086px;
  margin-top: 100px;
}

.stars-text {
  display: flex;
  height: 80px;
}

.star-box {
  text-align: center;
  display: block;
  width: 114px;
  font-size: 200px;
  font-weight: 500;
  line-height: 21%;
  font-family: var(--font-primary);
  margin-right: 38px;
  margin-bottom: 65px;
}

.stars-lines {
  margin-top: 50px;
  display: flex;
  gap: 38px;
}

.stars-line {
  background-color: #362D2C;
  height: 8px;
  width: 114px;
  border-radius: 2.5px;
}

.numbers-box {
  background-color: var(--secondary);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 105px;
  row-gap: 138px;
  padding: 147px;
  border-radius: 87px;
}

.circle-number {
  background-color: transparent;
  color: white;
  font-weight: 700;
  font-size: 100px;
  font-family: var(--font-secondary);
  line-height: 1;
  border: 2px solid white;
  border-radius: 50%;
  width: 176px;
  height: 176px;
  transition: all 0.3s;
}

.circle-number:active {
  background-color: #978d7c;
}

.circle-number:disabled {
  background-color: #978d7c;
  cursor: not-allowed;
}

.circle-number img {
  height: 100%;
  width: 75px;
  object-fit: contain;
}

.second-main-block {
  height: auto;
}

.arrow-box {
  position: absolute;
  right: 50px;
  bottom: 100px;
  width: 140px;
  height: 140px;
  z-index: 10;
  background-color: var(--secondary);
  border-radius: 50%;
  transition: all 0.3s;
  overflow: hidden;
}

.arrow-box:hover {
  background-color: #978d7c;
}

.arrow-box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(43deg) brightness(106%) contrast(102%);

}

.top-hosts-btn {
  background-color: var(--primary);
  color: black;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  padding: 50px;
  line-height: 1.66;
  border: 1px solid var(--secondary);
  border-radius: 102px;
  min-height: 180px;
  font-family: var(--font-secondary);
  width: 100%;
}

.top-hosts-btn img {
  object-fit: contain;
  height: 100%;
  width: auto;
}

.second-main-page-left-box {
  background-color: rgba(196, 196, 196, .5);
  width: 50%;
  height: 100%;
  padding-top: 552px;
  text-align: center;
}

.second-main-page-img-box {
  width: 880px;
  height: auto;
  margin: 0 auto;
}

.second-main-page-img-box img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.second-main-page-icon-box {
  width: 220px;
  height: 220px;
  margin-top: 110px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  animation: MoveUpDown 3s infinite;
}
.search-btn {
  position: absolute;
  right: -98px;
  z-index: 5;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: var(--secondary);
  align-items: center;
  top: -240px;
  img {
    width: 65px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7477%) hue-rotate(80deg) brightness(96%) contrast(109%);
  }
}
.numpad-btn button {
  background-color: transparent;
  border: none;
  margin-top: 20px;
  img {
    width: 100px;
    filter: brightness(0) saturate(100%) invert(52%) sepia(26%) saturate(209%) hue-rotate(358deg) brightness(90%) contrast(85%);
  }
} 
.numpad-btn {
  .numpad-text {
        font-size: 40px;
    }
    .numpad-text-open {
        display: none;
    }
    .numpad-text-active .numpad-text-hidden{
        display: none;
    }
    .numpad-text-active .numpad-text-open{
        display: block;
        color: #198754;
    }
}  
.keyboard-open {
  .text-box {
    padding: 0;
    transition: all .3s;
    visibility: hidden;
    opacity: 0;
    height: 375px;
  }
}
@keyframes MoveUpDown {

  0%,
  100% {
    transform: translateY(+60px);
  }

  50% {
    transform: translateY(0);
  }
}

.second-main-page-icon-box a {
  display: block;
}

.time-box {
  text-align: center;
}

.date {
  font-family: var(--font-secondary);
  font-size: 65px;
  line-height: 0.75;
  font-weight: 700;
}

.time {
  font-family: var(--font-secondary);
  font-size: 65px;
  line-height: 0.75;
  margin-top: 27px;
}

.left-number-box {
  margin: 0 auto;
  width: fit-content;
}

.exit-btn a,
.exit-btn button {
  position: absolute;
  top: 423px;
  right: 131px;
  width: 140px;
  height: 140px;
  border: none;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 4;
}

.exit-btn img {
  object-fit: contain;
  width: 80px;
  height: 80px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(234deg) brightness(103%) contrast(103%);
}

.hosts-block a {
  text-decoration: none;
  color: #362D2C;
  display: block;
}

.small-text-box {
  margin-top: 100px;
}

.small-text-box h3 {
  margin: 0;
  font-size: 100px;
  line-height: 0.7;
  font-family: var(--font-secondary);
  margin-bottom: 100px;
}

.small-text-box p {
  margin: 0;
  font-size: 60px;
  line-height: 1.15;
  font-family: var(--font-secondary);
}

.small-text-box span {
  font-weight: 700;
}

.tenant-modal {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(250, 250, 250, .95);
  &.touchpad-modal-open {
    .call-modal-btn,
    .modal-img-box {
      display: none !important;
    }
    .modal-text-box,
    .tenant-name {
      height: 0;
      opacity: 0;
      visibility: hidden;
      margin: 0 !important;
    }
    .numpad-btn img {
      filter: brightness(0) saturate(100%) invert(42%) sepia(13%) saturate(2297%) hue-rotate(100deg) brightness(95%) contrast(88%);
    }
    
  }
}

.tenant-modal-content {
  background-color: #E7E5E2;
  margin: 400px auto 90px auto;
  padding: 71px 54px 115px 54px;
  border-radius: 53px;
  width: 30%;
  height: 1705px;

  &.lg {
    width: 60%;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-img-box {
  height: 370px;
  width: auto;
  background-color: white;
  padding: 30px 130px;
  border-radius: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-img-box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.modal-text-box {
  font-size: 60px;
  line-height: 1.1;
  font-family: var(--font-secondary);
  margin: 30px auto;
  text-align: center;
  color: rgba(108, 117, 125, 1);
}

.modal-icons-box {
  display: flex;
  margin-top: 90px;
  gap: 180px;
  justify-content: center;
}

.modal-red-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(220, 53, 69, 1);
  opacity: .5;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: none;
  padding: 38px 42px;
  transition: all 0.3s;
}

.modal-red-box:hover {
  opacity: .7;
}

.modal-red-box img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.modal-green-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #198754;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  padding: 50px;
  border: none;
  transition: all 0.3s;
}

.modal-green-box:hover {
  background-color: #157247;
}

.modal-red-box img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.padlock.verified {
  position: absolute;
  right: 0;
  width: 450px;
  height: 450px;
  top: unset;
  left: unset;
}

.mt-6 {
  margin-top: 80px;
}

.main-btn {
  display: block;
  background-color: var(--secondary);
  color: white;
  font-family: var(--font-secondary);
  font-size: 50px;
  line-height: 2;
  text-decoration: none;
  border-radius: 125px;
  padding: 32px 64px;
  text-align: center;
  min-width: 860px;
  border: none;
}

.btn {
  background-color: var(--secondary);
  color: white;
  font-family: var(--font-secondary);
  font-size: 50px;
  line-height: 2;
  text-decoration: none;
  border-radius: 125px;
  padding: 32px 64px;
  text-align: center;
  min-width: 860px;
  border: none;
}

.small-text-box h3 {
  margin: 0;
  font-size: 100px;
  line-height: 1;
  font-family: var(--font-secondary);
  margin-bottom: 100px;
}

.overlay {
  z-index: 40 !important;

  .overlay-content {
    top: 10% !important;

    h1 {
      font-size: 180px;
    }
  }
}

.hang-phone,
.answer-phone {
  width: 200px;
  height: 200px;

  img {
    padding: 10px;
  }
}

.toast-top-right {
  top: 30px;
  right: 30px;
}

.toast-container .toast-error {
  background-color: var(--secondary) !important;
  font-size: 64px;
  color: #FFFFFF !important;
  border-radius: 50px;
  margin-bottom: 30px;
}

.toast-container>div {
  background-position: 50px;
  background-repeat: no-repeat;
  background-size: 120px 120px;
  padding: 70px 70px 70px 250px;
}

/*.hosts-block {
  column-gap: 190px;
  row-gap: 150px;
  height: 920px;
  overflow-x: hidden;
  padding-right: 150px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 10;
  position: relative;
  max-height: 920px;
  margin-right: -90px;
}*/


/*.hosts-block {
  column-gap: 190px;
  row-gap: 150px;
  height: 920px;
  overflow-x: hidden;
  padding-right: 150px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 10;
  position: relative;
  max-height: 920px;
  margin-right: -90px;

  &::-webkit-scrollbar {
    width: 42px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &::before, &::after {
    content: '';
    position: absolute;
    width: 52px;
    height: 52px;
    background-color: #888;
    cursor: pointer;
  }

  &::before {
    top: 0;
    right: 0;
    border-bottom: 12px solid #888;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }

  &::after {
    bottom: 0;
    right: 0;
    border-top: 12px solid #888;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
  }
}*/


.hosts-container {
  position: relative;
  height: 805px;
  .no-results {
    position: relative;
    z-index: 20;
  }
}

.hosts-block {
  column-gap: 145px;
  row-gap: 50px;
  max-height: 805px;
  overflow-x: hidden;
  padding-right: 60px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  z-index: 8;
  position: relative;
  margin-right: 0;
  scroll-snap-type: y mandatory;
  div {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}

.scroll-btn {
  position: absolute;
  right: -98px;
  width: 140px;
  height: 140px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  z-index: 10;
  background-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.top-scroll {
  top: 0;
  background-image: url('/assets/alto/icons/scroll-up.svg');
  background-color: var(--secondary);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: none;
}
.keyboard-open {
  .top-scroll {
    top: 230px;
  }
}
.bottom-scroll {
  bottom: 10px;
  background-image: url('/assets/alto/icons/scroll-down.svg');
  background-color: var(--secondary);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: none;
}






.hosts-block::-webkit-scrollbar {
  width: 140px;
  height: 140px;
}

/*.hosts-block::-webkit-scrollbar {
  width: 140px;
  height: 140px;
}

.hosts-block::-webkit-scrollbar-button:start:decrement,
.hosts-block::-webkit-scrollbar-button:end:increment,
.hosts-block::-webkit-scrollbar-button:start:decrement,
.hosts-block::-webkit-scrollbar-button:end:increment {
  display: block;
}

.hosts-block::-webkit-scrollbar-button:vertical:start:increment,
.hosts-block::-webkit-scrollbar-button:vertical:end:decrement,
.hosts-block::-webkit-scrollbar-track:vertical,
.hosts-block::-webkit-scrollbar-track-piece,
.hosts-block::-webkit-scrollbar-thumb:vertical,
.hosts-block::-webkit-scrollbar-button:start:increment,
.hosts-block::-webkit-scrollbar-button:end:decrement {
  display: none;
}

.hosts-block::-webkit-scrollbar-button:end:increment {
  background-image: url('/assets/alto/icons/scroll-down.svg');
  width: 140px;
  height: 140px;
  background-color: var(--secondary);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: none;
}

.hosts-block::-webkit-scrollbar-button:start:decrement {
  background-image: url('/assets/alto/icons/scroll-up.svg');
  width: 140px;
  height: 140px;
  background-color: var(--secondary);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: none;
}*/

/*** HEADER ***/

.header {
  padding-top: 120px;

  h3,.h3 {
    font-size: 70px;
  }

  .logo img {
    width: 290px;
  }

  .clock-container {
    .time{
      font-weight: 700;
      font-size: 125px;
    }
    .date {
      font-weight: 400;
      font-size: 70px;
      line-height: 1;
    }
  }
}

.welcome {
  text-align: center;

  h1 {
    font-size: 128px;
  }

}

.check-in {
  .btn {
    margin: 0 0 95px 0;
  }
}

.btn-primary {
  background-color: var(--primary-color);
}

input.hidden {
  width: 0;
  height: 0;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

/*** FOOTER ***/

.footer {
  position: absolute;
  right: 0;
  bottom: 250px;

  .lang {
    position: relative;
    right: 0;
    z-index: 1;

    button {
      padding: 0;
      border: none;
      font-size: 0.9em;
      line-height: 150px;
      width: 150px;
      display: block;
      text-align: center;
      font-weight: bold;
      color: #fff;
      background: var(--secondary-color);

      &:hover {
        background: var(--secondary-light-color);
      }

      &.is-active {
        background: #b5b5b5;
      }

      .btn-icon {
        width: auto;
        height: 100px;
        margin-bottom: 15px;
      }
    }

    .language-menu {
      display: flex;
      position: absolute;
      right: 175px;
      width: auto;
      transition: all .2s ease;
      transform: scale(0);
      transform-origin: right;

      &.show {
        transform: none;
      }

      button {
        margin-left: 30px;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 60px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

/*** OVERLAY ***/

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.75);
  overflow-x: hidden;
  transition: 0.5s;

  h3 {
    font-size: 100px;
  }

  &.white {
    background-color: rgba(255, 255, 255, 0.85);

    .overlay-content {
      color: #000000;
      top: 50%;
      padding: 0 300px;
    }
  }

  .loading {
    $colors: #ffffff, #ffffff, #ffffff, #ffffff, #ffffff;
    display: flex;

    .dot {
      font-size: 16px;
      position: relative;
      width: 2em;
      height: 2em;
      margin: 0.8em;
      border-radius: 50%;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: inherit;
        border-radius: inherit;
        animation: wave 2s ease-out infinite;
      }

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          background: nth($colors, $i);

          &::before {
            animation-delay: $i * 0.2s;
          }
        }
      }
    }
  }

  @keyframes wave {

    50%,
    75% {
      transform: scale(2.5);
    }

    80%,
    100% {
      opacity: 0;
    }
  }

  .overlay-content {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 0 300px;
    color: #FFFFFF;

    h5 {
      text-transform: none;
      font-size: 48px;
      transition: 0.3s;
      margin: 100px 0;
    }

    img {
      height: 300px;
    }
  }
}

/*** SCAN QR ***/

.buttons-arrow {
  padding-left: 100px;
  padding-right: 100px;

  img {
    width: 394px;
    transform: rotate(90deg);
  }
}

.animation-left-right {
  position: relative;
  animation: top-bottom 1.5s infinite ease-in-out;
}

.buttons-box {
  margin-top: 270px;

  .btn {
    margin-bottom: 70px;
    display: inline-block;
    margin-right: 100px;
  }
}

@keyframes top-bottom {

  0%,
  100% {
    top: 0;
  }

  50% {
    top: 100px;
  }
}

/*** SCAN QR ***/

/**********************************
            CALCULATOR
**********************************/

.calculator {
  width: 775px;
  border-width: 5px;
  border-style: solid;
  background: #fff;
  padding: 90px;
}

.padlock {
  left: 2065px;
  top: 160px;
}

.pagination-box span {
  line-height: 1.8;
  font-size: 64px;
}

.calculator-buttons {
  margin-bottom: 47px;
}

.btn-outline-secondary {
  font-weight: bold;
  padding: 0;
  width: 154px;
  height: 154px;
}

.code-block {
  min-height: 1452px;
}

.btn-c span {
  margin-top: -20px;
  display: block;
}

.btn-enter img {
  width: 90px;
  margin-top: -20px;
}

.pin-block {
  margin-top: 400px;
  flex: 1 0 auto;
  margin-left: 790px;

  .pin-nums {
    min-height: 180px;

    .pin-num {
      font-size: 180px;
      height: 180px;
      width: 150px;
      line-height: 1;
      font-weight: bold;

    }

    &.pin-dashs {
      min-height: 0;

      .pin-num {
        height: 40px;
        line-height: 40px;
      }
    }
  }

}

.pagination {
  padding: 65px 0 60px 0;
}

.pagination-box {
  font-weight: 300;
}

.pagination-left img {
  margin-right: 60px;
  width: 120px;
}

.pagination-right img {
  margin-left: 60px;
  width: 120px;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.75);
    overflow-x: hidden;
    transition: 0.5s;

    &.success-modal {
      .modal-dialog {
        width: 70%;
        height: 90%;
        top: 50% !important;
        position: absolute;
        background-color: #FFFFFF;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 !important;
      }
      .modal-content {
        padding: 220px 100px;
        height: 100%;
      }
      .exit-btn button{
        top: 80px;
        right: 80px;
      }
      .check-modal-text-box {
        img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
        h1,h2,h3,h4,h5 {
          margin-bottom: 32px;
          margin-top: 0;
          line-height: 1.2;
        }
        h1 {
          font-size: 160px; 
          font-weight: 700;
        }
        h2 {
          font-size: 138px;
          font-weight: 600;
        }
        h3 {
          font-size: 106px;
          font-weight: 600;
        }

        h4 {
          font-size: 95px;
          font-weight: 500;
        }
        h5 {
          font-size: 90px;
          font-weight: 500;
        }
        p {
          font-size: 70px;
          margin-bottom: 32px; 
        }
        ul,ol {
          padding-left: 120px;
        }
        li {
          margin-bottom: 24px;
          font-size: 70px;
        }
        a {
          text-decoration: underline;
        }
      }
  }
}